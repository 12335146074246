import GridItem from './grid-item';
import styles from './grid.module.scss';

type Props = {
  items: {
    image: string;
    title: string;
    link: string;
    path: string;
    tags: string[];
  }[];
};

const Grid = ({ items }: Props) => {
  return (
    <div className={styles.grid}>
      {items.map((item, index) => (
        <GridItem item={item} key={index} />
      ))}
    </div>
  );
};

export default Grid;
