import React from 'react';
import styles from './social.module.scss';
import { BsMailbox2 } from 'react-icons/bs';
import { RiGitlabFill } from 'react-icons/ri';
import { FaLinkedinIn } from 'react-icons/fa';

function Social() {
  return (
    <ul className={styles.social}>
      <li>
        <a href='mailto:hi@rhojn.com' title='Email'>
          <BsMailbox2 />
        </a>
      </li>
      <li>
        <a className="linkedin" href='https://www.linkedin.com/in/rhojen' title='LinkedIn'>
          <FaLinkedinIn />
        </a>
      </li>
      <li>
        <a className="gitlab" href='https://gitlab.com/rh0jn/public' title='GitLab'>
          <RiGitlabFill />
        </a>
      </li>
    </ul>
  );
}

export default Social;
