import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './grid.module.scss';
import { RiExternalLinkLine } from 'react-icons/ri'
import Tags from '../tags'

type Props = {
  link: string;
  path: string;
  tags: string[];
  active: boolean;
};

const GridOverlay = ({ link, path, tags, active }: Props) => {
  const overlayRef = useRef(null)
  const navigate = useNavigate();
  const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()

    if (event.target === overlayRef.current) {
      event.preventDefault();
      navigate(`/works/${path}`)
    }
  }
  return (
    <div ref={overlayRef} className={`${styles.gridOverlay} ${active ? 'active' : ''}`} onClick={onClick}>
      <div className="top">
        <a href={link} title="Visit" target="_blank"><RiExternalLinkLine /></a>
      </div>
      <div className="bottom">
        <Tags tags={tags} />
      </div>
    </div>
  );
};

export default GridOverlay;
