import React from 'react';
import Container from '../../../components/container';
import Section from '../../../components/section';
import styles from './hero.module.scss';
import Social from '../../../components/social';

function Hero() {
  return (
    <div className={styles.hero}>
      <Section>
        <Container>
          <h1>
            Hey, I’m Rhojen Ocenar
            <br />
            I'm a software engineer with a knack for creating human interfaces
            for the web<span>.</span>
          </h1>
          <p>
            I’m currently working as a <span>Software Engineer</span> at{' '}
            <span>Reekoh</span>, based in <span>Manila, Philippines</span>. I
            have a knack for developing web-based sites and applications using
            various web technologies and tools.
          </p>
          <Social />
        </Container>
      </Section>
    </div>
  );
}

export default Hero;
