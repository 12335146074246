import Container from '../../components/container';
import Tags from '../../components/tags';
import { WorksProps } from '../works/works.reducer';
import styles from './work-details.module.scss';

type Props = {
  work: WorksProps;
};

const Hero = ({ work }: Props) => {
  return (
    <div className={styles.hero_container}>
      <div className={styles.hero_details}>
        <Container>
          <div className={styles.topBar}>
            <h1>{work.title}</h1>
            <a className={styles.link} href={work.link} target='_blank'>
              Visit Link
            </a>
          </div>
          <Tags tags={work.tags} />
        </Container>
      </div>
      <figure className={styles.hero_bg}>
        <img src={work.screenshot} alt={work.title} />
      </figure>
    </div>
  );
};

export default Hero;
