import Header from '../header';
import Footer from '../footer';
import { Outlet, ScrollRestoration  } from 'react-router-dom'

function App() {

  return (
    <div className='App'>
      <Header />
      <Outlet />
      <Footer />
      <ScrollRestoration />
    </div>
  );
}

export default App;
