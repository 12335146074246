import styles from './work-details.module.scss';
import Container from '../../components/container';
import Hero from './hero';
import Content from './content';
import { WorksProps } from '../works/works.reducer';

type Props = {
  work: WorksProps;
};

const WorkDetails = ({ work }: Props) => {
  return (
    <main className={styles.workDetails}>
      <Hero work={work} />
      <Container>
        <Content work={work} />
      </Container>
    </main>
  );
};

export default WorkDetails;
