import { WorksProps } from '../works/works.reducer';
import styles from './work-details.module.scss';

type Props = {
  work: WorksProps;
};

const Content = ({ work }: Props) => {
  return (
    <div className={styles.content_container}>
      {work.path === 'rhojn' ? (
        <>
          <h2>Project Overview</h2>
          <p>
            This project is a personal website that showcases my skills and
            experience as a frontend developer. The website is built using
            React, TypeScript, Styled Components, a bit of Redux and React
            Router. It's currently deployed using Traefik, a reverse proxy and
            load balancer that enables me to manage and route web traffic to
            different services.
          </p>
          <p>
            As a solo developer, I was responsible for designing and developing
            the website, as well as deploying it to Traefik. My main goal for
            the project was to create a responsive and visually appealing
            website that would showcase my frontend development skills to
            potential employers and clients.
          </p>
          <h2>Technologies</h2>
          <ul>
            <li>React</li>
            <li>TypeScript</li>
            <li>Redux</li>
            <li>React Router</li>
            <li>Styled Components</li>
            <li>Traefik</li>
            <li>Docker</li>
          </ul>
          <h2>Key Features</h2>
          <ul>
            <li>
              Responsive Design: The website features a responsive design that
              looks great on desktop and mobile devices. The layout adjusts
              automatically to the user's screen size, providing an optimal
              viewing experience for all visitors.
            </li>
            <li>
              Works Section: The website includes a works section that showcases
              some of my personal projects. Each project includes a brief
              description, screenshots, and links to the live website and source
              code.
            </li>
            <li>
              About Me Section: The website also includes an "About Me" section
              that provides more information about my background and experience.
              This section includes a brief bio, and links to my social media
              profiles and resume.
            </li>
          </ul>
          <h2>Future Plans</h2>
          <p>
            In the future, I plan to add more projects to the works section and
            add a blog section to the website where I can share my thoughts and
            insights on frontend development and other related topics. I'm also
            planning to migrate the frontend to NextJS + Strapi.
          </p>
        </>
      ) : (
        <>
          <h2>Project Overview</h2>
          <p>
            This project is a web application that retrieves and displays news
            articles from the Hacker News API. The website is built using
            React.js with Redux and React Router for state management and
            routing, respectively.
          </p>
          <p>
            As a solo developer, I was responsible for designing and developing
            the website. My main goal for the project was to create a functional
            and visually appealing website that would replicate the core
            features of the Hacker News website.
          </p>
          <h2>Technologies</h2>
          <ul>
            <li>React</li>
            <li>Redux</li>
            <li>Reselect</li>
            <li>React Router</li>
            <li>Styled Components</li>
            <li>Hacker News API</li>
          </ul>
          <h2>Key Features</h2>
          <ul>
            <li>View a list of articles</li>
            <li>View articles from Ask, Show and Jobs</li>
            <li>Sort articles by date, popularity, or comments</li>
            <li>View individual articles and their comments</li>
          </ul>
        </>
      )}
    </div>
  );
};

export default Content;
