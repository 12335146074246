import { Link } from 'react-router-dom'
import styles from './grid.module.scss';

type Props = {
  title: string;
  path: string;
};

const GridDetails = ({ title, path }: Props) => {
  return (
    <div className={styles.gridDetails}>
      <Link to={path}>{title}</Link>
    </div>
  );
};

export default GridDetails;
