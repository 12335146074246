import Container from '../container';
import Brand from './brand';
import Links from './links';
import styles from './header.module.css';

const Header = () => (
  <header className={styles.header}>
    <Container>
      <div className={styles.wrapper}>
        <Brand />
        <Links />
      </div>
    </Container>
  </header>
);

export default Header;
