import styles from './tags.module.scss';

type Props = {
  tags: string[];
};

const Tags = ({ tags }: Props) => (
  <div className={styles.tags}>
    {tags.map((tag, index) => (
      <span className="tag" key={index}>
        {tag}
      </span>
    ))}
  </div>
);

export default Tags;
