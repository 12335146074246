import { useEffect } from 'react';
import Container from '../../../components/container';
import Section from '../../../components/section';
import Grid from '../../../components/grid';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { worksAsync, selectWorks } from '../../works/works.reducer';
import styles from './works.module.scss';
import { Link } from 'react-router-dom';

const Works = () => {
  const works = useAppSelector(selectWorks);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(worksAsync());
  }, [dispatch]);
  return (
    <Section>
      <Container>
        <div className={styles.works}>
          <div className={styles.worksHeader}>
            <h2>My Experiments</h2>
            <Link to='/works'>See All</Link>
          </div>
          <p>A collection of my side projects and experiments.</p>
          <Grid items={works} />
        </div>
      </Container>
    </Section>
  );
};

export default Works;
