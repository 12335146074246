import { NavLink } from 'react-router-dom';
import styles from './links.module.scss';

const Links = () => {
  const links: {
    to: string;
    label: string;
  }[] = [
    {
      to: '/',
      label: 'Home',
    },
    {
      to: '/works',
      label: 'Works',
    },
    {
      to: '/about',
      label: 'About',
    },
    {
      to: '/blog',
      label: 'Blog',
    },
  ];

  return (
    <ul className={styles.links}>
      {links.map((link, index) => (
        <li className={styles.link} key={index}>
          <NavLink to={link.to} end={link.to === '/'}>
            {link.label}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

export default Links;
