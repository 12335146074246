import React from 'react';
import Hero from './hero'
import Works from './works'
import Talk from './talk'

function Home() {
  return (
    <main>
      <Hero />
      <Works />
      <Talk />
    </main>
  );
}

export default Home;
