import React from 'react';
import Section from '../../../components/section';
import styles from './talk.module.scss';

const Talk = () => (
  <div className={styles.talk}>
    <Section>
      <div className={styles.content}>
        <h2>Have something in mind?</h2>
        <p>
          I’m currently working full time as a Software Engineer but am always
          open to any opportunity, collaboration, or just a chat about coding.
        </p>
        <a href='mailto:hi@rhojn.com'>hi@rhojn.com</a>
      </div>
    </Section>
  </div>
);

export default Talk;
