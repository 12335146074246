import React from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '../../components/container';
import styles from './work-in-progress.module.scss';

const WorkInProgress = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.workInProgress}>
      <Container>
        <div className={styles.status}>
          <span>418</span>
        </div>
        <p>Page unavailable.</p>
        <p>This page is currently under construction.</p>

        <button onClick={() => navigate('/')}>Home</button>
      </Container>
    </div>
  );
};

export default WorkInProgress;
