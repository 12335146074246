import { useEffect } from 'react';
import Container from '../../components/container';
import Grid from '../../components/grid';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { worksAsync, selectWorks } from './works.reducer';
import styles from './works.module.scss';

const Works = () => {
  const works = useAppSelector(selectWorks);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(worksAsync());
  }, [dispatch]);
  return (
    <main className={styles.works}>
      <Container>
        <h2>Works</h2>
        <p>A collection of my side projects and experiments.</p>
        <Grid items={works} />
      </Container>
    </main>
  );
};

export default Works;
