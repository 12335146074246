import Container from '../container';
import Social from '../social';
import styles from './footer.module.scss';

const Footer = () => (
  <div className={styles.footer}>
    <Container>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <p>
            rhojn<span>.</span>
          </p>
          <p>{new Date().getFullYear()} © All rights reserved.</p>
        </div>
        <div className={styles.social}>
          <p>Designed and built by <a href="https://rhojn.com">me</a> :)</p>
          <Social />
        </div>
      </div>
    </Container>
  </div>
);

export default Footer;
