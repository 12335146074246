import { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from '../components/app';
import Home from '../pages/home';
import About from '../pages/about';
import Works from '../pages/works';
import NotFound from '../pages/not-found';
import WorkInProgress from '../pages/work-in-progress';
import WorkDetails from '../pages/work-details';

import { useAppSelector, useAppDispatch } from '../store/hooks';
import { worksAsync, selectWorks } from '../pages/works/works.reducer';

function AppRoutes() {
  const works = useAppSelector(selectWorks);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(worksAsync());
  }, [dispatch]);

  const router = createBrowserRouter([
    {
      path: '/',
      element: <App />,
      errorElement: <NotFound />,
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: 'works',
          element: <Works />,
        },
        {
          path: 'about',
          element: <About />,
        },
        {
          path: 'blog',
          element: <WorkInProgress />,
        },
        ...works.map((work) => ({
          path: `/works/${work.path}`,
          element: <WorkDetails work={work} />,
        })),
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default AppRoutes;
