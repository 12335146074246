import { useState } from 'react';
import GridOverlay from './grid-overlay'
import styles from './grid.module.scss';

type Props = {
  image: string;
  title: string;
  link: string;
  path: string;
  tags: string[];
};

const GridBg = ({ image, title, path, link, tags }: Props) => {
  const [onHover, setOnHover] = useState(false)

  return (
    <div className={styles.gridBg} onMouseEnter={() => setOnHover(true)} onMouseLeave={() => setOnHover(false)}>
      <figure>
        <img src={image} alt={title} />
      </figure>
      <GridOverlay active={onHover} path={path} link={link} tags={tags} />
    </div>
  );
};

export default GridBg;
