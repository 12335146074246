import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { fetchWorks } from './works.api';

export type WorksProps = {
  image: string;
  screenshot: string;
  title: string;
  link: string;
  path: string;
  tags: string[];
};

export interface WorksState {
  data: WorksProps[];
  status: 'idle' | 'loading' | 'failed';
}

const initialState: WorksState = {
  data: [],
  status: 'idle',
};

export const worksAsync = createAsyncThunk('works/fetchWorks', async () => {
  const response = fetchWorks();
  return response;
});

export const worksSlice = createSlice({
  name: 'works',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(worksAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(worksAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload;
      })
      .addCase(worksAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectWorks = (state: RootState) => state.works.data;

export default worksSlice.reducer;
