import styles from './about.module.scss';
import Container from '../../components/container';

const About = () => {
  const skills: string[] = [
    'HTML5',
    'CSS3',
    'JavaScript/ES6',
    'TypeScript',
    'ReactJs',
    'AngularJs',
    'NodeJS',
    'ExpressJS',
    'MongoDB',
    'MySQL',
    'Git',
    'Docker',
    'Figma',
  ];

  const experiences: {
    company: string;
    position: string;
    date: string;
    descriptions: string[];
  }[] = [
    {
      company: 'Reekoh',
      position: 'Software Engineer',
      date: 'August 2021 - Present',
      descriptions: [
        'Design product solutions based on given customer requirements.',
        'Develop Node.js-based solutions to extend product capability and maintain software functionality.',
      ],
    },
    {
      company: 'RightCrowd Software Inc.',
      position: 'Software Engineer',
      date: 'August 2019 - August 2021',
      descriptions: [
        'Participate as a front-end developer in the development of a web-based software solution using AngularJS, NodeJS, and ArangoDB.',
        'Participate as a front-end developer in the development of a web portal for a software solution using MERN stack.',
      ],
    },
    {
      company: 'Project Assistant PH',
      position: 'Web Developer',
      date: 'March 2018 - July 2019',
      descriptions: [
        'Worked on more than 5 new and existing WordPress websites.',
        'Developed an admin portal for a mobile application using AngularJS.',
        'Participated as a front-end developer in the development of 3 web applications using the MERN stack.',
      ],
    },
  ];

  return (
    <main className={styles.about}>
      <Container>
        <h2>Hi there!</h2>

        <div className={styles.summary}>
          <div className={styles.summaryMain}>
            <p>
              I'm Rhojen Jonash Ocenar, a Frontend Software Engineer based in
              Manila, Philippines. I have more than 6 years of experience
              working with a range of technologies for both frontend and backend
              web development, including React, Redux, AngularJS, NodeJS,
              Express, PHP, and more.
            </p>
            <p>
              I am passionate about web development and enjoy collaborating with
              cross-functional teams to deliver high-quality web applications
              within project budgets and timelines. I am constantly staying
              up-to-date with the latest software development trends and
              technologies to ensure that my projects are always on the cutting
              edge.
            </p>

            <button onClick={() => window.location.assign('/resume.pdf')}>
              Resume
            </button>
          </div>
          <ul className={styles.summarySkills}>
            {skills.map((skill) => (
              <li key={skill}>{skill}</li>
            ))}
          </ul>
        </div>
      </Container>
      <div className={styles.summaryExperiences}>
        <Container>
          <h2>Work Experiences</h2>
          {experiences.map((experience, index) => (
            <div className={styles.summaryExperience} key={index}>
              <div className={styles.summaryExperienceColumn}>
                <h3>{experience.company}</h3>
                <p>{experience.date}</p>
              </div>
              <div className={styles.summaryExperienceColumn}>
                <h3>{experience.position}</h3>
                <ul>
                  {experience.descriptions.map((item, index) => (
                    <li key={index}>
                      <p>{item}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </Container>
      </div>
    </main>
  );
};

export default About;
