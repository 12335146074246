// A mock function to mimic making an async request for data
export function fetchWorks() {
  return [
    {
      path: 'rhojn',
      image: '/works/rhojn.png',
      screenshot: '/works/rhojn-screenshot.png',
      title: 'Personal Website',
      link: 'https://rhojn.com/',
      tags: ['TypeScript', 'ReactJS', 'Redux', 'Traefik'],
    },
    {
      path: 'hn',
      image: '/works/hn.png',
      screenshot: '/works/hn-screenshot.png',
      title: 'HN React',
      link: 'https://hn.rhojn.com/',
      tags: ['ReactJS', 'Redux'],
    },
  ];
}
