import GridBg from './grid-bg';
import GridDetails from './grid-details';
import styles from './grid.module.scss';

type Props = {
  item: {
    image: string;
    title: string;
    link: string;
    path: string;
    tags: string[];
  };
};

const GridItem = ({ item }: Props) => {
  return (
    <div className={styles.gridItem}>
        <GridBg image={item.image} title={item.title} path={item.path} link={item.link} tags={item.tags} />
        <GridDetails title={item.title} path={item.path} />
    </div>
  );
};

export default GridItem;
