import React from 'react';
import styles from './section.module.css';

type Props = {
  children: React.ReactNode;
};

const Section = ({ children }: Props) => (
  <section className={styles.section}>{children}</section>
);

export default Section;
